exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-ansatte-js": () => import("./../../../src/pages/ansatte.js" /* webpackChunkName: "component---src-pages-ansatte-js" */),
  "component---src-pages-blogg-[slug]-js": () => import("./../../../src/pages/blogg/[slug].js" /* webpackChunkName: "component---src-pages-blogg-[slug]-js" */),
  "component---src-pages-blogg-js": () => import("./../../../src/pages/blogg.js" /* webpackChunkName: "component---src-pages-blogg-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobbe-i-alv-[slug]-js": () => import("./../../../src/pages/jobbe-i-alv/[slug].js" /* webpackChunkName: "component---src-pages-jobbe-i-alv-[slug]-js" */),
  "component---src-pages-jobbe-i-alv-js": () => import("./../../../src/pages/jobbe-i-alv.js" /* webpackChunkName: "component---src-pages-jobbe-i-alv-js" */),
  "component---src-pages-kontakt-oss-js": () => import("./../../../src/pages/kontakt-oss.js" /* webpackChunkName: "component---src-pages-kontakt-oss-js" */),
  "component---src-pages-om-oss-[slug]-js": () => import("./../../../src/pages/om-oss/[slug].js" /* webpackChunkName: "component---src-pages-om-oss-[slug]-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-vi-tilbyr-js": () => import("./../../../src/pages/vi-tilbyr.js" /* webpackChunkName: "component---src-pages-vi-tilbyr-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-video-category-js": () => import("./../../../src/templates/videoCategory.js" /* webpackChunkName: "component---src-templates-video-category-js" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */),
  "component---src-templates-videoserie-js": () => import("./../../../src/templates/videoserie.js" /* webpackChunkName: "component---src-templates-videoserie-js" */)
}

