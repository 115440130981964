module.exports = [{
      plugin: require('../plugins/alv-gdpr-tracking/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManagerId":"GTM-NG6G4RZ","linkedInPixelId":"1500953"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alv AS","display":"standalone","icon":"src/favicons/favicon-32x32.png","lang":"no","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2f86983b1a1bb6a32aa3ae256ed63014"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
